declare global {
  interface Window {
    __ENV_CONFIG__: EnvVariables;
  }
}

export interface EnvVariables {
  amazonDemandPartnerId: string;
  apiRequestLimit: number;
  apiRequestLimitAccount: number;
  apiRequestLimitUserList: number;
  australiaAccountId: string;
  dealForecastingMaxRequestCount: number;
  dealForecastingRefetchInterval: number;
  dealTroubleshootingMaxRequestCount: number;
  dealTroubleshootingRefetchInterval: number;
  dv360DemandPartnerId: string;
  tablePageSize: number;
  ttdDemandPartnerId: string;
  xandrDemandPartnerId: string;
  source?: string;
  ssoAuthority?: string;
  ssoClientId?: string;
  ssoEmailSecurityKey: string;
  ssoRedirectUri?: string;
  pendoApiKey?: string;
}

export const uiParametersProd: EnvVariables = {
  amazonDemandPartnerId: '537073219',
  apiRequestLimit: 50,
  apiRequestLimitAccount: 9999,
  apiRequestLimitUserList: 200,
  australiaAccountId: 'e28733bf-7811-4d67-915d-9becf36e44a6',
  dealForecastingMaxRequestCount: 60,
  dealForecastingRefetchInterval: 5000,
  dealTroubleshootingMaxRequestCount: 60,
  dealTroubleshootingRefetchInterval: 5000,
  dv360DemandPartnerId: '537073246',
  source: 'fallback',
  ssoEmailSecurityKey: 'HOkULc7fa5DhOcJ0',
  tablePageSize: 50,
  ttdDemandPartnerId: '537073292',
  xandrDemandPartnerId: '537072400',
};

export function getEnvVariables(): EnvVariables {
  const envVariables = window.__ENV_CONFIG__ ?? {};

  const envVars = { ...uiParametersProd, ...envVariables };

  const numVal = [
    'apiRequestLimit',
    'apiRequestLimitAccount',
    'apiRequestLimitUserList',
    'dealForecastingMaxRequestCount',
    'dealForecastingRefetchInterval',
    'tablePageSize',
  ];
  numVal.forEach(key => {
    if (envVars[key] && typeof envVars[key] === 'string') {
      envVars[key] = parseInt(envVars[key]);
    }
  });

  return envVars;
}
