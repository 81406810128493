import validator from 'validator';

export const isUrlValid = (url: string | undefined): boolean => {
  return (
    !!url &&
    validator.isURL(url, {
      allow_underscores: true,
      require_protocol: false,
    })
  );
};
